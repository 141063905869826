import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import "./layout.css";
import { Bounce, ToastContainer } from "react-toastify";

const Layout = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="warpper">
        <aside className={`asidebar ${isMenuOpen ? "Sidebaractive" : ""}`}>
          <Sidebar />
        </aside>
        <header className="app-header sticky-top px-4">
          <Navbar isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
        </header>
        <main className={`main-container ${isMenuOpen ? "active" : ""}`}>
          
          <Outlet />
        </main>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
      </div>
    </>
  );
};

export default Layout;
