import React, { useEffect, useState } from "react";
import HeroSection from "../../components/DashboardSection/HeroSection";
import { API } from "../../service/axiosInstance";
import { MdOutlineDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BlogManager = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nodata, setNoData] = useState(false);
  const [allBlogData, setAllBlogData] = useState([]);

  useEffect(() => {
    fetchBlogData();
  }, []);

  const fetchBlogData = async () => {
    try {
      setError(null);
      setNoData(false);
      setLoading(true);
      const response = await API.getAllBlog({});
      if (response) {
        if (response.isSuccess) {
          setLoading(false);
          if (response.data.length > 0) {
            setAllBlogData(response.data);
          } else {
            setNoData(true);
          }
        }
      } else {
        setLoading(false);
        setError("Something went wrong!");
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
      if (error.originalMessage) {
        setError(error.originalMessage);
      } else {
        setError(error.message.message);
      }
    }
  };

  const deleteBlogTempById = async (blog) => {
    try {
      if (window.confirm("Are you sure you want to delete this blog?") === true) {
        const response = await toast.promise(API.updateBlog({ ...blog, activeStatus: false }, true), {
          pending: "Deleting category...",
          success: "Category Deleted👌",
          error: "Error deleting Blog!🤯",
        });
        if (response.isSuccess) {
          const updateBlogData = JSON.parse(response.data);

          setAllBlogData((prevBlogs) => prevBlogs.filter((singleBlog) => singleBlog._id !== updateBlogData._id));

          console.log("deleted documents", JSON.parse(response.data));
        }
        console.log("deleting blog", response);
      } else {
      }
    } catch (error) {
      console.error("error deleting blog", error);
      // setLoading()
      // toast.error("Error deleting blog");
    }
  };

  const editBlog = async (blog) => {
    console.log("blog", blog);
    navigate(`/blogmanager/edit/${blog?._id}`);
  };
  return (
    <div className="container-fluid p-4">
      {/* <BlogManagerHeroGrid /> */}
      <HeroSection usedfor="BlogManager" />
      <div className="row gy-4">
        {allBlogData.length > 0 &&
          allBlogData.map((blog, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <div className="card shadow">
                <img className="card-img-top img-fluid" src={blog.blogHeroImage}  alt=""/>
                <div className="card-body">
                  <h2 className="card-title fs-6 lh-normal fw-semibold">{blog.title}</h2>
                  <p className="line-3">{blog.description}</p>
                </div>

                <div className="bg-white border-0 bottom-0 card-header-btn card-footer position-absolute start-0 w-100">
                  <div className="card-header-btn d-flex align-items-center gap-2 justify-content-end">
                    <button className="iconbutton  btn fs-5 btn-success shadow">
                      <MdOutlineRemoveRedEye />
                    </button>
                    <button
                      className="iconbutton  btn fs-5 btn-success shadow"
                      onClick={() => {
                        editBlog(blog);
                      }}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="iconbutton  btn fs-5 btn-success shadow"
                      onClick={() => {
                        deleteBlogTempById(blog);
                      }}
                    >
                      <MdOutlineDelete />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {loading && <>loading...</>}
      {nodata && !loading && <small className="text-secondary">-- No blog found --</small>}
      {error && !loading && <small className="text-danger">{error}</small>}
    </div>
  );
};

export default BlogManager;
