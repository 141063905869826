import React from "react";
import "./dashboardSection.css";
import { heroSectionButtonData } from "../../constants/data";
import { useNavigate } from "react-router-dom";

const HeroSection = ({ usedfor }) => {
	const navigate = useNavigate();
	const handleHeroBtn = (data) => {
		console.log("heroBtnClick", data);
		data.link && navigate(data.link);
	};
	return (
		<div className={`${usedfor}_heroContainer row gy-4 mb-4`}>
			{heroSectionButtonData.map((item) =>
				(usedfor === "Dashboard" && item.dashbordView) || (usedfor === "BlogManager" && item.blogManagerView) ? (
					<div className={`${item.id} column col-md-6 col-lg-6`} key={item.id}>
						<div className="cardContent position-relative shadow card h-100" >
							<div className="card-body">
								<h2 className="fs-5 card-title fw-semibold">{item.title}</h2>
								<p>{item.desc}</p>
							</div>
							<div className="card-footer bg-white">
								<button
									className="btn btn-success btn-md "
									onClick={() => {
										handleHeroBtn(item);
									}}
								>
									{item.buttonText}
								</button>
							</div>

						</div>
					</div>
				) : null
			)}
			{usedfor && usedfor === "Dashboard" && <div className="column5 column"></div>}
		</div>
	);
};

export default HeroSection;
