import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdOutlineCancel, MdOutlineRemoveRedEye, MdUploadFile } from "react-icons/md";
import { IoIosCloseCircle, IoMdCheckmark } from "react-icons/io";
import ReactQuill from "react-quill";
import { generateSlug } from "../../utils/utils";
import { toast } from "react-toastify";
import { API } from "../../service/axiosInstance";
import { useParams } from "react-router-dom";
import { uploadFile } from "../../utils/sdk-factory";
import { LuUploadCloud } from "react-icons/lu";
import { useSelector } from "react-redux";

const CreateBlog = ({ usedFor }) => {
  const {userDetails} = useSelector(state=>state.admin)
  const { id } = useParams();
  const [state, setState] = useState({
    title: "",
    slug: "",
    description: "",
    isTrending: false,
    blogHeroImage: null,
    heroAlt:null
  });
  const [metaSeodetails, setMetaSeoDetails]= useState({
    metaTitle:"",
    metaDesc:"",
    metaKeyword:""
  })
  const [isBlogOpen, setIsBlogOpen] = useState(false);
  const quillRef = useRef();
  const fileInputRef = useRef(null);
  const [content, setContent] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);
  const [keywordArray, setKeywordArray]= useState([])
  const [error, setError] = useState({ status: false, message: "" });

  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor();
    console.log(editor);
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        console.log(file);
        const alt = prompt("Enter the image description (alt text):");
        const formData = new FormData();
        formData.append("image", file);
        const res = await uploadFile(file, "BlogHeroImage");

        const url = res;
        editor.insertEmbed(editor.getSelection(), "image", url);
        setTimeout(() => {
          const editorContents = editor.root;
          const img = editorContents.querySelector(`img[src="${url}"]`);
          if (img) {
            img.setAttribute('alt', alt);
          }
        }, 100);
      } else {
        alert("error");
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "color", "image"],
          [{ "code-block": true }],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "indent",
    "image",
    "code-block",
    "color",
  ];

  useEffect(() => {
    if (id) {
      fetchInitialBlogData(id);
    }
    fetchCategories();
  }, []);

  const fetchInitialBlogData = async (id) => {
    try {
      setInitialLoading(true);
      const response = await API.getBlogById({ id });

      if (response) {
        if (response.isSuccess) {
          setInitialLoading(false);
          const { title, slug, isTrending, description, content: initialContent, blogHeroImage, selectedCat, metaData } = response?.data;
          // console.log("blogbyId", isTrending, content);
          setState({ title, slug, isTrending, description, blogHeroImage });
          metaData&&setMetaSeoDetails({metaTitle:metaData?.title, metaDesc:metaData?.description})
          metaData && setKeywordArray(metaData.keywords.filter(keyword => !["Layer Matrix", "Feku Sharma"].some(substring => keyword.includes(substring))));
          selectedCat && setSelectedCat(selectedCat);
          setContent(initialContent);
        }
      }
    } catch (error) {
      // setI
    }
  };
  const fetchCategories = async () => {
    try {
      setError({ status: false, message: null });
      setLoading(true);
      const response = await API.getAllCategories({});
      if (response) {
        if (response.isSuccess) {
          setLoading(false);
          if (response.data.length > 0) {
            setAllCategories(response.data);
          } else {
            // setNoData(true);
          }
        }
      } else {
        setLoading(false);
        setError({ status: true, message: "Something went wrong!" });
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
      if (error.originalMessage) {
        setError({ status: true, message: error.originalMessage });
      } else {
        setError({ status: true, message: error.message.message });
      }
    }
  };
  const handleBlogInput = (e) => {
    error.status && setError({ status: false, message: null });
    const { name, type, checked, value } = e.target;
    if (name === "title") {
      const autoSlug = generateSlug(value);
      setState({ ...state, title: value, slug: autoSlug });
    } else {
      setState({ ...state, [name]: type === "checkbox" ? checked : value });
    }
  };
  const handleSeoInput=(e)=>{
    error.status && setError({ status: false, message: null });
    const { name, type, checked, value } = e.target;
    setMetaSeoDetails({ ...metaSeodetails, [name]: type === "checkbox" ? checked : value });
  }

  const handleBlogSubmit = async () => {
    try {
      if (loading) {
        return;
      }
      if (!state.title || !state.slug || !metaSeodetails.metaTitle || !metaSeodetails.metaDesc ||!keywordArray.length>0) {
        setError({ status: true, message: "Please fill the (*) mark field" });
        toast.error("Please fill the (*) mark field !");
        return;
      }
      setError({ status: false, message: null });
      setLoading(true);
      const payload = { ...state, content, selectedCat, metaData:{title:metaSeodetails.metaTitle,description:metaSeodetails.metaDesc, keywords:[...keywordArray, "Layer Matrix", "Feku Sharma"],author: 'Layer Matrix', userDetails} };

      const response =
        usedFor && usedFor == "edit"
          ? await toast.promise(API.updateBlog({ ...payload, _id: id }, true), {
              pending: "Updating...",
              success: `"/${state.slug}" blog updated successfully`,
              error: "Error updating blog!🤯",
            })
          : await toast.promise(API.createBlog(payload, true), {
              pending: "Publishing...",
              success: `"/${state.slug}" blog created successfully`,
              error: "Error publishing blog!🤯",
            });
      setLoading(false);
      console.log("response of blog publishing", response);
    } catch (error) {
      setError({ status: true, message: error.originalMessage ? error.originalMessage : error.message.message });
      toast.error(error.originalMessage ? error.originalMessage : error.message.message);
      console.error("Error submitting blog", error);
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const HeroImage = await uploadFile(file, `BlogHeroImage`);
    const alt = prompt("Enter description of hero image (alt text:)")
    setState({ ...state, blogHeroImage: HeroImage, heroAlt:alt });
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleCatDropdownClick = (cat) => {
    setSelectedCat((prevSelectedCat) => {
      // Check if the category is already included
      if (prevSelectedCat.some((selected) => selected.categoryId === cat.categoryId)) {
        return prevSelectedCat.filter((selected) => selected.categoryId !== cat.categoryId);
      } else {
        return [...prevSelectedCat, cat]; // Otherwise, add the new category
      }
    });
  };

  const handleKeywordAdd = (e) => {
    e.preventDefault();
    if(!metaSeodetails.metaKeyword){
      return
    }
    setKeywordArray((prevKeywordArray) => {
      return [...prevKeywordArray, metaSeodetails.metaKeyword];
    });
    setMetaSeoDetails({ ...metaSeodetails, metaKeyword: "" });
  };
  const handleKeywordDelete = (itemToRemove) => {
    setKeywordArray((prevKeywordArray) => prevKeywordArray.filter(item => item !== itemToRemove));
  };
 //test 
  return (
    <>
      {initialLoading ? (
        <>Loading...</>
      ) : (
        <>
          <div className="d-flex justify-content-end">
            <button className="btn btn-light m-1">
              <MdOutlineRemoveRedEye />
            </button>
            <button className="btn btn-primary m-1" onClick={() => handleBlogSubmit()}>
              {userDetails.role=="admin"?"Submit for Review" : "Publish"}
            </button>
          </div>

          <div className="form container">
            <h3>Blog Editor</h3>
            <form>
              <div>
                <h5>Seo Details</h5>
                <div className="mb-3 mt-3">
                  <label htmlFor="metaTitle">
                    Meta title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaTitle"
                    placeholder="Enter your blog meta title..."
                    name="metaTitle"
                    value={metaSeodetails.metaTitle}
                    onChange={handleSeoInput}
                  />
                </div>

                <div className="mb-3 mt-3">
                  <label htmlFor="metaDesc">
                    Meta description <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metaDesc"
                    placeholder="Enter your blog meta description..."
                    name="metaDesc"
                    value={metaSeodetails.metaDesc}
                    onChange={handleSeoInput}
                  />
                </div>
                <div className="mb-3 mt-3 ">
                  <label htmlFor="metaKeyword">Meta keywords</label>
                  <div className="align-items-center d-flex flex-wrap gap-2">
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        id="metaKeyword"
                        placeholder="Enter ketwords..."
                        name="metaKeyword"
                        value={metaSeodetails.metaKeyword}
                        onChange={handleSeoInput}
                      />
                    </div>
                    <div className="">
                      <button
                        className="btn btn-outline-primary"
                        onClick={(e) => {
                          handleKeywordAdd(e);
                        }}
                      >
                        Add
                      </button>
                    </div>
                    {keywordArray &&
                      keywordArray.length > 0 &&
                      keywordArray.map((keyword, i) => (
                        <div className="position-relative">
                          <span key={i} className="p-2 fw-medium bg-secondary badge small h-100">
                            {keyword}
                          </span>
                         <div onClick={()=>{handleKeywordDelete(keyword)}} style={{right:"-5px", top:"-5px"}} className="position-absolute"> <MdOutlineCancel  /></div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div>
                <h5>Blog Details</h5>
                <div className="mb-3 mt-3">
                  <label htmlFor="blogTitle">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="blogTitle"
                    placeholder="Enter your blog title..."
                    name="title"
                    value={state.title}
                    onChange={handleBlogInput}
                  />
                </div>
                <div className="mb-3 mt-3">
                  <label htmlFor="blogSlug">
                    Slug <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="blogSlug"
                    placeholder="Enter your blog slug..."
                    name="slug"
                    value={state.slug}
                    onChange={handleBlogInput}
                  />
                </div>
                <div className="mb-3 mt-3">
                  <label htmlFor="blogDesc">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="blogDesc"
                    placeholder="Enter your blog description..."
                    name="description"
                    value={state.description}
                    onChange={handleBlogInput}
                  />
                </div>
                <div style={{ height: "150px", background: "#f0f8ff", borderRadius: "10px" }} className="d-flex justify-content-center">
                  {state.blogHeroImage ? (
                    <>
                      <button
                        style={{ right: "0px" }}
                        className="btn p-1 position-absolute "
                        onClick={() => {
                          setState({ ...state, blogHeroImage: null, heroAlt: null });
                        }}
                      >
                        <IoIosCloseCircle />
                      </button>
                      <img src={state.blogHeroImage} alt={state.heroAlt || "heroalt"} className="p-2" />
                    </>
                  ) : (
                    <>
                      <input
                        ref={fileInputRef}
                        className="d-none"
                        type="file"
                        id="avatar1"
                        name="avatar"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleFileChange(e)}
                      />
                      <button
                        className="btn"
                        onClick={(e) => {
                          handleButtonClick(e);
                        }}
                      >
                        <LuUploadCloud />
                        <span id="Browsercontent">Upload Hero image</span>
                      </button>
                    </>
                  )}
                </div>

                <div className="align-items-center d-flex mb-3 mt-3">
                  <div className="dropdown mb-3 mt-3">
                    <button className="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Select Categories
                    </button>
                    <ul className="dropdown-menu overflow-auto" style={{ maxHeight: "200px" }}>
                      {allCategories &&
                        allCategories.length > 0 &&
                        allCategories.map((cat, i) => (
                          <li
                            key={i}
                            className={`dropdown-item d-flex justify-content-between`}
                            onClick={() => {
                              handleCatDropdownClick(cat);
                            }}
                          >
                            <div>{cat.categoryName}</div>
                            {selectedCat.some((selected) => selected.categoryId === cat.categoryId) && <IoMdCheckmark />}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {selectedCat &&
                    selectedCat.length > 0 &&
                    selectedCat.map((selCat, i) => (
                      <span key={i} className="p-2 m-1 bg-dark-subtle small" style={{ borderRadius: "20px", height: "fit-content" }}>
                        {selCat.categoryName}
                      </span>
                    ))}
                </div>

                <div className="mb-3 mt-3">
                  <label htmlFor="blogContent">
                    Content <span className="text-danger">*</span>
                  </label>
                  <ReactQuill ref={quillRef} id="content" theme="snow" modules={modules} formats={formats} value={content} onChange={setContent} />
                </div>
                <div className="form-check mb-3">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" name="isTrending" checked={state.isTrending} onChange={handleBlogInput} />{" "}
                    Trending
                  </label>
                </div>
              </div>
              {error.status && <small className="text-danger">{error.message}</small>}
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CreateBlog;
