import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage } from "firebase/storage";

// Your Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCKXdMe7B2Och1MkLWCAbKzFf3Fm2qy-bs",
    authDomain: "layermatrixblogsite.firebaseapp.com",
    projectId: "layermatrixblogsite",
    storageBucket: "layermatrixblogsite.appspot.com",
    messagingSenderId: "266570049089",
    appId: "1:266570049089:web:c0deb18e171b98cd67c8c0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Firebase Storage service
const storage = getStorage(app);
const auth = getAuth(app);

const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    console.log('User logged in:', userCredential.user);
    // User is now authenticated
  } catch (error) {
    console.error('Error logging in:', error);
  }
};
login('fekusharma111@gmail.com', 'FirebaseStorage1234@#$');
export { storage };
