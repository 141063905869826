import React, { useState } from "react";
import "./sidebar.css";
import { AllIcons, adminSideBarLinks } from "../../constants/data";
import { Link } from "react-router-dom";

const Sidebar = () => {
  let BrandName = "LM";
  const [selectedLink, setSelectedLink] = useState(adminSideBarLinks[0]);

  return (
    <>
      <div className="app-logo brandName  sticky-top">
        <h1 className="fs-2 text-start fw-semibold">{BrandName}</h1>
        {/* <p className="lead text-center">Admin Panel</p> */}
      </div>
<div className="app-sidenav">
      <ul className="">
        {adminSideBarLinks.map(
          (item,i) =>
            item.visible && (
              <li key={i}>
                <Link
                  to={item.link}
                  key={item.id}
                  className={`d-flex align-items-center sidelink gap-2 ${item.id === selectedLink?.id ? "active" : ""}`}
                  onClick={() => setSelectedLink(item)}
                >
                  {AllIcons[item.id] && <span className="svgicon">{AllIcons[item.id]}</span>}
                  <span className="link-title">{item.title}</span>
                </Link>
              </li>
            )
        )}
      </ul>
      </div>
    </>
  );
};

export default Sidebar;
