import React from "react";
import { MdOutlineNotifications, MdAccountCircle, MdSearch } from "react-icons/md";
import { adminNavLinks } from "../../constants/data";

const Navbar = ({ isMenuOpen, setMenuOpen }) => {
  const handleSidebar = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
  
    <div className="nav app-header-left-menu">

      <button
        className="btn fs-4 p-0 text-white"
        onClick={() => {
          handleSidebar();
        }}
      >
        <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H5C4.44772 7 4 6.55228 4 6Z" fill="currentColor"></path><path d="M4 18C4 17.4477 4.44772 17 5 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5C4.44772 19 4 18.5523 4 18Z" fill="currentColor"></path><path d="M5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11H5Z" fill="currentColor"></path></svg>
      </button>
      </div>

      <div className="nav app-header-right-menu">
        <button className="btn text-white fs-4 p-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"></path></svg>
        </button>
        <ul className="dropdown-menu">
          <li>
            <a href="" className="dropdown-item">
              sdf
            </a>
          </li>
        </ul>
      </div>
      </>
  );
};

export default Navbar;
