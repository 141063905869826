// firebaseUtils.js

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase';
// import { storage } from './firebase'; // Import your Firebase storage instance

export const uploadFile = async (file, uploadingPath) => {
  try {
    // Create a reference to the storage location where the file will be uploaded
    const storageRef = ref(storage, `${uploadingPath}/${file.name}`);
    debugger
    // Upload the file to the storage location
    await uploadBytes(storageRef, file);
    
    // Get the download URL of the uploaded file
    const url = await getDownloadURL(storageRef);
    
    // Return the download URL
    return url;
  } catch (error) {
    // Handle errors
    console.error("Error uploading file:", error);
    // Rethrow the error to allow the calling code to handle it
    throw error;
  }
};
